import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const SustainablePerformance = () => {
  return (
    <Container className="sustainable-performance p-x-30 ">
      <Row className="justify-content-between">
        <Col
          data-sal="fade"
          data-sal-delay="50"
          data-sal-easing="ease"
          data-sal-duration="1000"
          xs={12}
          lg={5}
        >
          <div className="section-content mb-0">
            <h4 className="section-title">
              <span>Sustainable</span>
              <span>Performance</span>
            </h4>

            <p>
              We are committed to excellence in everything we do. Our future
              success is intertwined with the well-being of our employees,
              partners and the communities around us. This commitment is
              reflected in the steps we are taking and the innovative approaches
              we are adopting to build on the resilience of the firm and achieve
              positive impacts that make a tangible difference in improving
              wider environmental and social outcomes.
            </p>
            <p>
              We have initiatives and programs to deliver on our ESG commitment,
              which is underpinned by the strong foundation of our core values
              and standards.
            </p>
          </div>
        </Col>
        <Col
          data-sal="fade"
          data-sal-delay="50"
          data-sal-easing="ease"
          data-sal-duration="1000"
          className="information-container"
          xs={12}
          lg={6}
        >
          <div className="section-content">
            <h3 className="section-title">Environmental</h3>
            <p>
              A commitment to protecting the environment underpins all our
              activities and operations. We take seriously the need to support
              national and international efforts to preserve natural resources
              in areas that we will have influence in for generations to come.
            </p>
          </div>

          <div className="section-content">
            <h3 className="section-title">Social</h3>
            <p>
              Another principle that sits at the heart of our operations is the
              need to treat our employees and partners with respect and
              professionalism, fostering a working environment where people can
              contribute, innovate and excel.
            </p>
          </div>

          <div className="section-content">
            <h3 className="section-title">Governance</h3>
            <p>
              Responsible governance is interwoven into our business model and
              enshrined in our policies and by-laws. We conduct our business
              with transparency, accountability and integrity by maintaining the
              highest professional and ethical standards towards our partners,
              communities and one another.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default SustainablePerformance
