import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const OurMission = () => {
  return (
    <Container className="our-mission text-center d-flex align-items-center justify-content-center">
      <Row
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="1000"
        className="flex-column align-items-center justify-content-center"
      >
        <Col>
          <h2 className="text-uppercase">Our Mission</h2>
        </Col>
        <Col>
          <h3>
            To provide the runway for disruptive ideas, supporting entrepreneurs
            in their efforts to achieve sustainable value, and drive economic
            diversity, business innovation and entrepreneurship
          </h3>
        </Col>
      </Row>
    </Container>
  )
}

export default OurMission
