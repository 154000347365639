import React from "react"
import Image from "@components/common/image"
import { Container, Row, Col } from "react-bootstrap"

const ExperiencedLeadership = () => {
  return (
    <div className="experienced-leadership">
      <Container className="p-x-30 ">
        <Row className="section-row">
          <Col
            className="section-image"
            data-sal="slide-right"
            data-sal-delay="0"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <Image
              className="w-100"
              src="who-we-are2.png"
              title="Experienced Leadership"
              alt="Experienced Leadership"
            />
          </Col>
          <Col
            className="section-content"
            data-sal="slide-left"
            data-sal-delay="0"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <div className="content-container">
              <h4 className="section-title">
                <span>Experienced</span>
                <span>Leadership</span>
              </h4>
              <p>
                Our entrepreneurial background comes handy in our role as an
                investor. We are passionate about starting, running and growing
                new ventures. Our investment team brings together a depth of
                expertise in private equity, corporate finance, business
                development and operational excellence.
              </p>
            </div>
          </Col>
        </Row>

        <Row className="section-row">
          <Col className="section-image">
            <Image
              className="w-100"
              src="HK Final@2x.png"
              title="Hamdan Al-Ketbi"
              alt="Hamdan Al-Ketbi"
            />
          </Col>
          <Col className="section-content">
            <div className="content-container">
              <h3 className="section-name">Hamdan Al-Ketbi</h3>
              <h2 className="section-designation">Managing Director</h2>
              <p>
                Committed to supporting the UAE’s entrepreneurial community,
                Hamdan is on a mission to create a radical investment platform
                that is capable of cultivating innovative and eccentric business
                concepts. He is regarded as a young, ambitious leader of a
                family-business legacy, passed on from one generation to
                another. The inherited passion for entrepreneurship, Innovation,
                and human development drives his current leadership role at Al
                Falaj Investment.
              </p>
              <div className="section-links">
                <a
                  href="https://www.linkedin.com/in/hamdan-al-ketbi/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className=""
                    src="linkedin@2x.png"
                    title="Linkedin Hamdan Al-Ketbi"
                    alt="Linkedin Hamdan Al-Ketbi"
                  />
                </a>
              </div>
            </div>
          </Col>
        </Row>

        {/* <Row className="section-row">
          <Col className="section-image">
            <Image
              className="w-100"
              src="MJ Photo@2x.png"
              title="Mohamad Jihad Abdel Ghani"
              alt="Mohamad Jihad Abdel Ghani"
            />
          </Col>
          <Col className="section-content">
            <div className="content-container">
              <h3 className="section-name">Mohamad Jihad Abdel Ghani</h3>
              <h2 className="section-designation">Head of Ventures</h2>
              <p>
                Mohamad is a socially-intelligent human being, with a strategic
                mindset and an entrepreneurial spirit. Acquired 15+ years of
                direct experience starting, running, and growing new ventures,
                with a solid track-record in identifying potential venture
                partners, crafting partnership proposals, and driving the
                proposals to a close. He has led on several business development
                programs, and understand the practicalities and challenges of
                working in small, fast-changing organizations, and with
                entrepreneurs.
              </p>
              <div className="section-links">
                <a
                  href="https://www.linkedin.com/in/mohamad-jihad-abdel-ghani-5451a327/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className=""
                    src="linkedin@2x.png"
                    title="Linkedin Hamdan Al-Ketbi"
                    alt="Linkedin Hamdan Al-Ketbi"
                  />
                </a>
              </div>
            </div>
          </Col>
        </Row> */}
      </Container>
    </div>
  )
}

export default ExperiencedLeadership
