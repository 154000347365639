import React from "react"

import Layout from "@components/layout"
import SEO from "@components/common/seo"

import OurMission from "@components/views/who-we-are/our-mission";
import OurStory from "@components/views/who-we-are/our-story";
import ExperiencedLeadership from "@components/views/who-we-are/experienced-leadership";
import SustainablePerformance from "@components/views/who-we-are/sustainable-performance";
import OurCoreValues from "../components/views/who-we-are/our-core-values";

import ApplyForFunding from "@components/views/home/apply-for-funding"
import PartnersTestimonial from "@components/views/home/partners-testimonial";

const WhoWeArePage = () => (
  <Layout className="who-we-are">
    <SEO title="Who We Are" />
    <OurMission />
    <OurStory />
    <ExperiencedLeadership />

    <div className="performance-values-wrapper">
      <SustainablePerformance />
      <OurCoreValues />
    </div>

    <PartnersTestimonial />

    <ApplyForFunding />
  </Layout>
)

export default WhoWeArePage
