import React from "react"
import Image from "@components/common/image"
import { Col, Container, Row } from "react-bootstrap"

const OurStory = () => {
  return (
    <div className="our-story">
      <Container className="text-center">
        <Row className="justify-content-lg-center">
          <Col
            xs={12}
            lg={11}
            className="p-x-30 our-story--image-wrapper"
            data-sal="fade"
            data-sal-delay="500"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <Image
              className="w-100"
              src="who-we-are1.png"
              alt="Our Story"
              title="Our Story"
            />
          </Col>
        </Row>

        <Row className="our-story--content-wrapper flex-column align-items-center justify-content-center">
          <Col
            data-sal="fade"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <h2 className="section-title">Our Story</h2>
          </Col>
          <Col
            data-sal="fade"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <h3>
              Evolving from a traditional family office into an ambitious
              Venture Capital and Private Equity firm
            </h3>
          </Col>
          <Col
            data-sal="fade"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <h2 class="section-subtitle">
              Led by a new generation of entrepreneurial investors, Al Falaj has
              allocated more than $20 million into new ventures and young
              enterprises, poised to scale-up in a wide range of sectors across
              MENA, Europe and South East Asia.
            </h2>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default OurStory
