import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Image from "@components/common/image"
import { Link } from "gatsby"

const OurCoreValues = () => {
  return (
    <Container className="our-core-values p-x-30 ">
      <Row className="justify-content-between">
        <Col
          data-sal="fade"
          data-sal-delay="50"
          data-sal-easing="ease"
          data-sal-duration="1000"
          xs={12}
          md={4}
          lg={3}
        >
          <div className="section-content">
            <h4 className="section-title">
              <span>Our </span> <span className="highlight">Core </span>{" "}
              <span>Values</span>
            </h4>

            <Link to="/investment-philosophy/" className="btn btn-secondary text-white">
              Investment Philosophy
            </Link>
          </div>
        </Col>

        <Col xs={12} md={8} lg={9}>
          <Row>
            <Col
              data-sal="fade"
              data-sal-delay="50"
              data-sal-easing="ease"
              data-sal-duration="1000"
              xs={12}
              md={6}
              className="section-content"
            >
              <Image
                className="section-icon"
                src="head@2x.png"
                alt="Innovation"
                title="Innovation"
              />
              <h2 className="section-subtitle">Innovation</h2>
              <p>
                In the ventures we fund, the founders that we support and the
                way we operate
              </p>
            </Col>
            <Col
              data-sal="fade"
              data-sal-delay="50"
              data-sal-easing="ease"
              data-sal-duration="1000"
              xs={12}
              md={6}
              className="section-content"
            >
              <Image
                className="section-icon"
                src="chat@2x.png"
                alt="Integrity"
                title="Integrity"
              />
              <h2 className="section-subtitle">Integrity</h2>
              <p>
                In all that we say and do, with open and transparent channels of
                communication across our value chain
              </p>
            </Col>

            <Col
              data-sal="fade"
              data-sal-delay="50"
              data-sal-easing="ease"
              data-sal-duration="1000"
              xs={12}
              md={6}
              className="section-content"
            >
              <Image
                className="section-icon"
                src="ecology@2x.png"
                alt="Sustainability"
                title="Sustainability"
              />
              <h2 className="section-subtitle">Sustainability</h2>
              <p>
                Long-term value from the investments we make, and tangible
                benefits for our stakeholders
              </p>
            </Col>
            <Col
              data-sal="fade"
              data-sal-delay="50"
              data-sal-easing="ease"
              data-sal-duration="1000"
              xs={12}
              md={6}
              className="section-content"
            >
              <Image
                className="section-icon"
                src="hula-hoop@2x.png"
                alt="Agililty"
                title="Agililty"
              />
              <h2 className="section-subtitle">Agililty</h2>
              <p>
                Pragmatic and adaptable in the decision-making process, flexible
                and accommodative to those we work with
              </p>
            </Col>

            <Col
              data-sal="fade"
              data-sal-delay="50"
              data-sal-easing="ease"
              data-sal-duration="1000"
              xs={12}
              md={6}
              className="section-content"
            >
              <Image
                className="section-icon"
                src="connect@2x.png"
                alt="Connectivity"
                title="Connectivity"
              />
              <h2 className="section-subtitle">Connectivity</h2>
              <p>
                Providing businesses and their leaders with unrivaled access to
                extensive networks of industry experts that supports them in
                scaling-up their ideas and operations
              </p>
            </Col>
            <Col
              data-sal="fade"
              data-sal-delay="50"
              data-sal-easing="ease"
              data-sal-duration="1000"
              xs={12}
              md={6}
              className="section-content"
            >
              <Image
                className="section-icon"
                src="star@2x.png"
                alt="Talent"
                title="Talent"
              />
              <h2 className="section-subtitle">Talent</h2>
              <p>
                An unmatched commitment to the professional development and
                mentorship of entrepreneurs, whose careers we foster and grow
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default OurCoreValues
